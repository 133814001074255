main {
  width: 100%;
  display: flex;
  align-content: stretch;
  padding: 0 ;
}

.page__wrapper {
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 2;
}

.page-content {
  padding: 0 20px;
  flex-grow: 2;
}

.spinner-margin-top {
  margin-top:100px;
}

.version-number {
  position: absolute;
  padding: 1rem;
  padding-bottom: 3rem;;
  bottom: 0;
  left:0;
  right:0;
  margin: 0 auto;
  text-align: center;
  opacity: .6;
}