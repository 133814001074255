.question-card__wrapper {
   border-radius: 5px;
}
.question-card__content {
padding: 10px;
display: flex;
justify-content: space-between;
align-items: center;
}

.question-card-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.question-card-h2,
.question-card-p {
    margin: .2rem;
}

.question-card-h2 {
    font-size: 2rem;
}

.question-card-p {
    font-size: 1.5rem;
    color: var(--dark);
}

.question-card-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.answered {
    opacity: .3;
}

.question-answered-badge {
    position: absolute;
    left:65%;
    text-align: center;
    padding: 0 20px;
    color: green;
   font-weight:100;
   font-size: 1rem;
   border: 1px solid green;
   border-radius: 10px;
   background-color: rgba(13, 167, 13, 0.3);
   display: flex;
   align-items: center;

}

.question-selected-badge {
    position: absolute;
    left:45%;
    text-align: center;
    padding: 0 20px;
    color: var(--secondary);
   font-weight:100;
   font-size: 1rem;
   border: 1px solid var(--secondary);;
   border-radius: 10px;
   background-color: rgba(145, 82, 228, 0.3);
   display: flex;
   align-items: center;

}

.question-selected-badge h1 ,
.question-answered-badge h1 {
    margin: 0;
    font-weight:300;
    font-size: 1.6rem;
}

.question-warning__wrapper {
    max-width: 300px;
}
.question-warning__wrapper h1 {
    margin: 1rem 0;
    margin-bottom: 0;
    font-size: 3.2rem;
}
.question-warning__wrapper p {
    line-height: 2rem;
}
.question-warning__actions {
    width: 100%;
    display: flex;
}
.question-warning__button {
    flex-grow: 2;
    margin: 3px;
    margin-top: 20px;
}