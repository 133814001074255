.show-card__wrapper {
    width:300px;
    text-align: center;
    background-color: var(--light);
}

.show-card__wrapper h2 {
    font-size: 3rem;
}

.show-card__wrapper p {
    font-size: 2rem;
    color:var(--secondary);
}

.show-card__wrapper {
    cursor: pointer;
    opacity: .8;
    transition: .3s;
}

.show-card__wrapper:hover {
    opacity: 1;
}