#podium_page {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 5;
    background-color: var(--dark);
    display: flex;
    flex-direction: column;

   
}

#podium_page h1 {
    padding: 20px;
    font-size: 3rem;
    font-weight: 400;
    border-bottom: 1px dashed var(--primary);
}

.podium-question-container {
    background-color: rgba(255,255,255,.1);
    flex-grow: 2;
    width: 100vw;
    overflow-x: scroll;
   
}