#add-question {
    margin: 10px 0;
    width: 100%;
    display: flex;
}

.add-question-form {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    padding:20px;
    background-color: rgba(255,255,255, .3);
    border-radius: 15px;;
}

.add-question-input-outer_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.add-question-input-wrapper {
    margin-bottom: 0rem;
}
.add-question-input {
    margin: 0 5px;
    margin-bottom: 0rem !important;
}

.add-qustion-btn {
    margin: 0px;
    margin-top: 1.4rem;

}

.add-question-inputError {
    color: white;
    margin-left: 11px;
}