.podium-question-card__wrapper {
   border-radius: 5px;
   position: relative;
}
.podium-question-card__content {
padding: 10px;
display: flex;
justify-content: space-between;
align-items: center;
}

.podium-question-card-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.podium-question-card-h2,
.podium-question-card-p {
    margin: .2rem;
}

.podium-question-card-h2 {
    font-size: 2rem;
}

.podium-question-card-p {
    font-size: 1.5rem;
    color: var(--dark);
}

.podium-question-card-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.podium-answered {
    opacity: .5;
    color: var(--dark);
}

.podium-question-answered-badge {
    position: absolute;
    left:55%;
    text-align: center;
    padding: 0 20px;
    color: green;
   font-weight:100;
   font-size: 1rem;
   border: 1px solid green;
   border-radius: 10px;
   background-color: rgba(13, 167, 13, 0.3);
   display: flex;
   align-items: center;

}

.podium-question-selected-badge {
    position: absolute;
    right:20px;
    text-align: center;
    padding: 0 20px;
    color: var(--secondary);
   font-weight:100;
   font-size: 1rem;
   border: 1px solid var(--secondary);;
   border-radius: 10px;
   background-color: rgba(145, 82, 228, 0.3);
   display: flex;
   align-items: center;

}

.podium-question-selected-badge h2 ,
.podium-question-answered-badge h2 {
    margin: 0;
    font-weight:300;
    font-size: 1.2rem;
}

.hidden {
    display: none;
}

