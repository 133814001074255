@import url(https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

:root {
  --primary: #FCB026; 
  --secondary: #333;
  --dark: #111111;
  --light: #E7DFDD;

  --input-color: #99A3BA;
  --input-border: #CDD9ED;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: #27A1B7;

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #EEF4FF;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);

}

html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: -webkit-flex;
  display: flex;
  color: #FCB026;
  color: var(--primary);
  background-color: #111111;
  background-color: var(--dark);
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: right;
}

.left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
}

.page-wrapper {
  margin: 0 auto;
  padding-top: 9rem;
  margin-bottom: 5.6rem;
  width: 100%;
  max-width: 1750px;
  min-height: 500px;
}


.clear-header {
  padding-top: 90px !important;
}


#root {
  width: 100%;
  display: -webkit-flex;
  display: flex;
}
.logo__wrapper {
    margin: 0px 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100px;  
    width: 100%;
    overflow: hidden;
}

.logo-img{
    width: 70%;
}
.main-nav__wrapper {
    width: 100%;
}

.nav-links {
    margin: 20px 0;
    padding: 0;
    width: 100%;
    list-style: none;
   

}

.nav-button {
    border: none;
    width: 100%;
}

.nav-links li a, .nav-button {
    display: -webkit-flex;
    display: flex;
    color: var(--secondary);
    text-decoration: none;
    margin: 0;
    padding: 15px;
    font-size: 1.3rem;
    font-weight: 500;
    
    border-bottom: 1px solid #c3c3c3;
    transition: .6s;
    z-index: -1;

    -webkit-flex-direction: column;

            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.nav-links li a:hover,
.nav-button:hover,
.nav-links li a:active, 
.nav-button:active, 
.nav-links li a:focus, 
.nav-button:focus 
{
    background-color: whitesmoke;
}

.sidebar__wrapper {
  position: fixed;
  width: 80px;
  height: 100vh;
  background-color: var(--light);
  border-left: 1px solid #a0a0a0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
}

.link-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: stretch;
          justify-content: stretch;
  -webkit-align-items: center;
          align-items: center;
}

.avatar-icon-wrapper {
    margin: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid var(--primary);
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
    z-index: 15;
}

.avatar-icon-wrapper img {
   min-width: 100%; 
   min-height: 100%;
   object-fit: cover;
}
.page-header__wrapper {
    margin: 0;
    padding: 5px;
    background-color: var(--primary);
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    height: 45px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  }

  .page-title {
      margin: 0;
      font-weight: 200;
      font-size: 2.5rem;
      margin-left: 20px;
  }

  .tutor-welcome__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 10px;
  }

  .welcome-text {
      padding: 0;
      margin-right: 10px;
      font-size: 1.8rem;
      font-weight: 200;
  }

  .logout {
      background-color: transparent;
      display: block;
      border: none;
      color: white;
      padding: 10px;
      font-size: 2rem;
      transition: .3s;
      cursor: pointer;
  }

  .logout:hover {
      color: var(--secondary-500);
  }

  .logout:active, 
  .logout:focus {
    border: none;
    outline: none;
}
  
.card {
    margin: 5px;
    border-radius: 10px;
    background-color: var(--light);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .3);
    
}

.card-header {
    border-radius: 10px 10px 0px 0px;
    padding-left: 10px;
    background-color: var(--secondary);
    color: white;
}

.card-header h3 {
    margin: 0;
    padding: 5px;
}


.primary-card {
    background-color: var(--primary);
}

.secondary-card {
    background-color: var(--secondary);
}

.dark-card {
    background-color: #333;
}
.show-card__wrapper {
    width:300px;
    text-align: center;
    background-color: var(--light);
}

.show-card__wrapper h2 {
    font-size: 3rem;
}

.show-card__wrapper p {
    font-size: 2rem;
    color:var(--secondary);
}

.show-card__wrapper {
    cursor: pointer;
    opacity: .8;
    transition: .3s;
}

.show-card__wrapper:hover {
    opacity: 1;
}
.button {
    margin: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 5px;
    border-width: 1px;
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: all .35s;
}

.button span{
    position: relative;
    z-index: 2;
  }

.button:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--primary-500);
    transition: all .35s;
    border-radius: 5px;
  }

.button:hover:after {
    width: 100%;
  }

.button:focus, .button:active {
    border: 1px solid white;
    outline: none;
}

.button-40 {
    width: 40%;
}

.button-50 {
    width: 50%;
}

.button-100 {
    width: 100%;
}

.button-primary {
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
}
.button-primary:after{
    background-color: var(--primary-500);
}


.button-secondary {
    background-color: var(--secondary);
    border: 1px solid var(--secondary-500);
    color: white;
}
.button-secondary:after{
    background-color: var(--secondary-500);
}

.button-danger {
    background-color: #B70F0F;
    border: 1px solid #D53D3D;
    color: white;
}
.button-danger:after{
    background-color: #770808;
}

.button-green {
    background-color: #168612;
    border: 1px solid #10640d;
    color: white;
}
.button-green:after{
    background-color: #10640d;
}

.button-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #7B6715;
}
.button-warning:after{
    background-color: #e0a800;
}

.button-outline-primary{
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}
.button-outline-primary:after{
    background-color: white;
}

.button-disabled {
    background-color: gray;
    color: darkgray;
    border: 1px solid darkgray;
}

.button-disabled:after {
    background-color: gray;
}





.lds-roller {
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    position: relative;
    width: 80px;
    height: 80px;
    padding: 10px;
    padding-bottom: 50px;
    border-radius: 5px;
  }
  .lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 40px 40px;
            transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background:  white;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }

  #lds-roller-text {
      margin-top: 90px;
      margin-left: auto;
      font-size: 20px;
      color: var(--primary);
      text-align: center;
  }
  @-webkit-keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
.input-wrapper {
    margin-bottom: 1rem;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.form-field {
    display: block;
    width: 100%;
    padding: .8rem 1.6rem;
    line-height: 2.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    -webkit-appearance: none;
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border .3s ease; 
}

.no-group {
    border-radius: 6px!important;
}

.form-field::-webkit-input-placeholder {
    color: var(--input-placeholder);
}

.form-field:-ms-input-placeholder {
    color: var(--input-placeholder);
}

.form-field::placeholder {
    color: var(--input-placeholder);
}

.form-field:focus {
    outline: none;
    border-color: var(--input-border-focus);
}

.form-group {
    margin: 0;
    position: relative;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.form-padding {
    margin: 0 10%;
}

.form-group span, .form-group .form-field {
    white-space: nowrap;
    display: block;
    border-radius: 6px;
}

.form-group .form-field:not(:first-child):not(:last-child),
.form-group span:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.form-group .form-field:first-child,
.form-group span:first-child {
    border-radius: 6px 0 0 6px;
}

.form-group .form-field:last-child,
.form-group span:last-child{
    border-radius: 0 6px 6px 0;
}

.form-group .form-field:not(:first-child),
.form-group .span:not(:first-child) {
    margin-left: -.1rem;
}


.form-group .form-field {
    position: relative;
    z-index: 1;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
}

.form-group span {
    text-align: center;
    padding: .8rem 1.2rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background .3s ease, border .3s ease, color .3s ease;
}

.form-group:focus-within span {
        color: var(--group-color-focus);
        background: var(--group-background-focus);
        border-color: var(--group-border-focus);
    }

   

   .inputError {
       
        color: darkred;
        font-weight: 600;
    
}
.AddShowForm {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width:500px;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    -webkit-align-self: stretch;
            align-self: stretch;
}

.add-show-form {
    min-width:500px;
}

.add-show-inputError {
    font-size: 1.6rem;
    color: darkred;
}

.add-show-model-actions {
display: -webkit-flex;
display: flex;
}

.add-show-submit {
    width: 50%;
}

.show-form-checkbox-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
}
.show-list-wrapper {
    display:-webkit-flex;
    display:flex;
   width: 100%;
}

.show-list-content {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
}
.sub-menu__wrapper {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    height: 40px;
    background-color:var(--dark);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
}

.sub-menu-list {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    font-size: 1.5rem;
    
}

.sub-menu-item {
    padding: 3px 15px;
    color:white;
    border-right: 1px dotted var(--primary);
}

.sub-menu-item-btn {
    background-color: transparent;
    outline: none;
    border: none;
    color:white;
    font-size: 1.5rem;
    transition: .3s;
    cursor: pointer;
    display: block;
}

.sub-menu-item-btn:hover,
.sub-menu-item-btn:focus {
    outline: none;
    border: none;
    color: var(--primary);
}


.switch {
    position: relative;
    display: inline-block;
    min-width: 60px;
    height: 34px;
    margin-left: 20px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: darkgray;
    transition: 0.4s;
  }

  .slider:focus {
      
      outline: none;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }

  input:focus + .slider {
    box-shadow: 0 0 3px rgba(0,0,0,.8);

  }

  input:checked + .slider {
    background-color: var(--primary);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px !important;
  }

  .slider.round:before {
    border-radius: 50%;
  }



.show-list-wrapper {
    display:-webkit-flex;
    display:flex;
   width: 100%;
}

.show-list-content {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
}

.show-header-deatils {
    margin:3px;
}
.show-header-deatils-tag {
    color: white;
    opacity: .8;
}

.show-actions {
    width: 100%;
    display: -webkit-flex;
    display: flex;
}
.question-list-continer {
    -webkit-flex-grow: 2;
            flex-grow: 2;
    overflow-x: scroll;
}
#add-question {
    margin: 10px 0;
    width: 100%;
    display: -webkit-flex;
    display: flex;
}

.add-question-form {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: stretch;
            align-items: stretch;
    padding:20px;
    background-color: rgba(255,255,255, .3);
    border-radius: 15px;;
}

.add-question-input-outer_wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
}

.add-question-input-wrapper {
    margin-bottom: 0rem;
}
.add-question-input {
    margin: 0 5px;
    margin-bottom: 0rem !important;
}

.add-qustion-btn {
    margin: 0px;
    margin-top: 1.4rem;

}

.add-question-inputError {
    color: white;
    margin-left: 11px;
}
.question-card__wrapper {
   border-radius: 5px;
}
.question-card__content {
padding: 10px;
display: -webkit-flex;
display: flex;
-webkit-justify-content: space-between;
        justify-content: space-between;
-webkit-align-items: center;
        align-items: center;
}

.question-card-details {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.question-card-h2,
.question-card-p {
    margin: .2rem;
}

.question-card-h2 {
    font-size: 2rem;
}

.question-card-p {
    font-size: 1.5rem;
    color: var(--dark);
}

.question-card-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
}

.answered {
    opacity: .3;
}

.question-answered-badge {
    position: absolute;
    left:65%;
    text-align: center;
    padding: 0 20px;
    color: green;
   font-weight:100;
   font-size: 1rem;
   border: 1px solid green;
   border-radius: 10px;
   background-color: rgba(13, 167, 13, 0.3);
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;

}

.question-selected-badge {
    position: absolute;
    left:45%;
    text-align: center;
    padding: 0 20px;
    color: var(--secondary);
   font-weight:100;
   font-size: 1rem;
   border: 1px solid var(--secondary);;
   border-radius: 10px;
   background-color: rgba(145, 82, 228, 0.3);
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;

}

.question-selected-badge h1 ,
.question-answered-badge h1 {
    margin: 0;
    font-weight:300;
    font-size: 1.6rem;
}

.question-warning__wrapper {
    max-width: 300px;
}
.question-warning__wrapper h1 {
    margin: 1rem 0;
    margin-bottom: 0;
    font-size: 3.2rem;
}
.question-warning__wrapper p {
    line-height: 2rem;
}
.question-warning__actions {
    width: 100%;
    display: -webkit-flex;
    display: flex;
}
.question-warning__button {
    -webkit-flex-grow: 2;
            flex-grow: 2;
    margin: 3px;
    margin-top: 20px;
}
.question-list-wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
}

.question-list-content {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.auth-wrapper {
    width: 90%;
    max-width: 500px;
    padding-top: 10%;
}
.login-card,
.forgot-card {
    margin-top: 15%;
    padding-bottom: 2rem;
    font-size: 1.6rem;
}

.login-card h1 {
    padding-top: 3rem;
    font-weight: 300;
    text-align: left;
    margin-bottom: 0;
    margin-left: 20%;
    color: var(--secondary);
}
.login-card p {
    margin-left: 20%;
    margin-top: 0;
   font-size: 1.4rem;
    font-weight: 500;
    text-align: left;
    color: var(--secondary);
}


.login-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

}

.login-btn {
    font-size: 2rem;
    font-weight: 400;
    width: 50%;
    
}

.enrol-btn {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    width: 30%;
}

.enrol-cta-container {
    margin: 7px;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.enrol-cta-container p {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
}

.forgot-card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}


.reset-sent-notice {
    color: var(--secondary);
    margin: 0;
    padding: 10px 20px;
}

.spinner-center {
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
}
.avatar-wrapper {
    margin: 0;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid var(--primary);
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
    z-index: 15;
}

.avatar-wrapper img {
    min-width: 100%; 
   min-height: 100%;
   object-fit: cover;
}
.upload-pimg-btn {
    font-size: 2rem;
    font-weight: 400;
    width: 90%;
    margin-left: 10%;
}

.enrol-avatar {
    position: absolute;
    top: 5.5rem;
    right: -11rem;
}

input[type="file"] {
    display: none;
}


.signup-card {
    margin-top: 15%;
}

.signup-card h1 {
    padding-top: 3rem;
}

.signup-form {
    position: relative;

}

.signup-btn {
    font-size: 2rem;
    font-weight: 400;
    width: 50%;
    
}

.enrol-btn {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    width: 30%;
}


main {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: stretch;
          align-content: stretch;
  padding: 0 ;
}

.page__wrapper {
  margin-left: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-grow: 2;
          flex-grow: 2;
}

.page-content {
  padding: 0 20px;
  -webkit-flex-grow: 2;
          flex-grow: 2;
}

.spinner-margin-top {
  margin-top:100px;
}

.version-number {
  position: absolute;
  padding: 1rem;
  padding-bottom: 3rem;;
  bottom: 0;
  left:0;
  right:0;
  margin: 0 auto;
  text-align: center;
  opacity: .6;
}
.podium-question-card__wrapper {
   border-radius: 5px;
   position: relative;
}
.podium-question-card__content {
padding: 10px;
display: -webkit-flex;
display: flex;
-webkit-justify-content: space-between;
        justify-content: space-between;
-webkit-align-items: center;
        align-items: center;
}

.podium-question-card-details {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.podium-question-card-h2,
.podium-question-card-p {
    margin: .2rem;
}

.podium-question-card-h2 {
    font-size: 2rem;
}

.podium-question-card-p {
    font-size: 1.5rem;
    color: var(--dark);
}

.podium-question-card-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
}

.podium-answered {
    opacity: .5;
    color: var(--dark);
}

.podium-question-answered-badge {
    position: absolute;
    left:55%;
    text-align: center;
    padding: 0 20px;
    color: green;
   font-weight:100;
   font-size: 1rem;
   border: 1px solid green;
   border-radius: 10px;
   background-color: rgba(13, 167, 13, 0.3);
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;

}

.podium-question-selected-badge {
    position: absolute;
    right:20px;
    text-align: center;
    padding: 0 20px;
    color: var(--secondary);
   font-weight:100;
   font-size: 1rem;
   border: 1px solid var(--secondary);;
   border-radius: 10px;
   background-color: rgba(145, 82, 228, 0.3);
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;

}

.podium-question-selected-badge h2 ,
.podium-question-answered-badge h2 {
    margin: 0;
    font-weight:300;
    font-size: 1.2rem;
}

.hidden {
    display: none;
}


.podium-question-list-wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
}

.podium-question-list-content {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 20px;
}
#podium_page {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 5;
    background-color: var(--dark);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

   
}

#podium_page h1 {
    padding: 20px;
    font-size: 3rem;
    font-weight: 400;
    border-bottom: 1px dashed var(--primary);
}

.podium-question-container {
    background-color: rgba(255,255,255,.1);
    -webkit-flex-grow: 2;
            flex-grow: 2;
    width: 100vw;
    overflow-x: scroll;
   
}
