.upload-pimg-btn {
    font-size: 2rem;
    font-weight: 400;
    width: 90%;
    margin-left: 10%;
}

.enrol-avatar {
    position: absolute;
    top: 5.5rem;
    right: -11rem;
}

input[type="file"] {
    display: none;
}

