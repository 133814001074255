.signup-card {
    margin-top: 15%;
}

.signup-card h1 {
    padding-top: 3rem;
}

.signup-form {
    position: relative;

}

.signup-btn {
    font-size: 2rem;
    font-weight: 400;
    width: 50%;
    
}

.enrol-btn {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    width: 30%;
}

