.AddShowForm {
    display: flex;
    flex-direction: column;
    min-width:500px;
    justify-content: stretch;
    align-self: stretch;
}

.add-show-form {
    min-width:500px;
}

.add-show-inputError {
    font-size: 1.6rem;
    color: darkred;
}

.add-show-model-actions {
display: flex;
}

.add-show-submit {
    width: 50%;
}

.show-form-checkbox-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}