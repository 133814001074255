.auth-wrapper {
    width: 90%;
    max-width: 500px;
    padding-top: 10%;
}
.login-card,
.forgot-card {
    margin-top: 15%;
    padding-bottom: 2rem;
    font-size: 1.6rem;
}

.login-card h1 {
    padding-top: 3rem;
    font-weight: 300;
    text-align: left;
    margin-bottom: 0;
    margin-left: 20%;
    color: var(--secondary);
}
.login-card p {
    margin-left: 20%;
    margin-top: 0;
   font-size: 1.4rem;
    font-weight: 500;
    text-align: left;
    color: var(--secondary);
}


.login-form {
    display: flex;
    flex-wrap: wrap;

}

.login-btn {
    font-size: 2rem;
    font-weight: 400;
    width: 50%;
    
}

.enrol-btn {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    width: 30%;
}

.enrol-cta-container {
    margin: 7px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.enrol-cta-container p {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
}

.forgot-card {
    display: flex;
    flex-direction: column;
}


.reset-sent-notice {
    color: var(--secondary);
    margin: 0;
    padding: 10px 20px;
}

.spinner-center {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}