.podium-question-list-wrapper {
    width: 100%;
    display: flex;
}

.podium-question-list-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}