.logo__wrapper {
    margin: 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;  
    width: 100%;
    overflow: hidden;
}

.logo-img{
    width: 70%;
}