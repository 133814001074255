

.show-list-wrapper {
    display:flex;
   width: 100%;
}

.show-list-content {
    display:flex;
    flex-wrap: wrap;
    width: 100%;
}

.show-header-deatils {
    margin:3px;
}
.show-header-deatils-tag {
    color: white;
    opacity: .8;
}

.show-actions {
    width: 100%;
    display: flex;
}
.question-list-continer {
    flex-grow: 2;
    overflow-x: scroll;
}